:root {
	--primary-color: #253d98;
	--primary-alpha: #564dbc;
	--light-blue: #ece5ff;
	--primary-bita: #8775cc;
	--primary-gama: #b0a1de;
	--primary-gita: #d9cfee;
	--secondary-color: #ffdd00;
	--secondary-alpha: #ffea63;
	--secondary-bita: #fff19a;
	--secondary-gama: #fff7cc;
	--success-primary: #14804a;
	--success-secondary: #8ff3c3;
	--light-green: #d9ffec;
	--danger-primary: #b12100;
	--danger-secondary: #ffc8bc;
	--widgetcount-text-color: #686868;
	--grident-background: linear-gradient(90deg, #253d98 22.72%, #8a85c7 100%),
		#d9d9d9;
	--widgetcount-background: #d9d9d9;
	--white: #ffffff;
	--textColor: rgba(23, 28, 38, 0.8);
	--defaultColor: #1c1d22;
	--muteColor: rgba(28, 29, 34, 0.7);
	--table-text-color: #646473;
	--w-full: 100%;
	--w-90: 90%;
	--w-80: 80%;
	--w-75: 75%;
	--w-half: 50%;
	--w-25: 25%;
	--h-full: 100%;
	--h-screen: 100vh;
}

/* custom scrollbar */
/* ::-webkit-scrollbar {
	width: 6px;
	border-radius: 0px;
	height: 4px;
}

::-webkit-scrollbar-track {
	background: var(--white);
}

::-webkit-scrollbar-thumb {
	background: var(--primary-color);
	border-radius: 0px;
}

::-webkit-scrollbar-thumb:hover {
	background: var(--primary-alpha);
} */

/*hide arraow in number input*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.nav-list::-webkit-scrollbar,
.sub-nav-list::-webkit-scrollbar {
	width: 5px;
	border-radius: 5px;
}

.nav-list::-webkit-scrollbar-track,
.sub-nav-list::-webkit-scrollbar-track {
	background: #ffffff;
}

.nav-list::-webkit-scrollbar-thumb,
.sub-nav-list::-webkit-scrollbar-thumb {
	background: #cccccc;
	border-radius: 5px;
}

.nav-list::-webkit-scrollbar-thumb:hover,
.sub-nav-list::-webkit-scrollbar-thumb:hover {
	background: #333333;
}

input[type="number"] {
	-moz-appearance: textfield;
}

/*---*/
body {
	margin: 0;
	padding: 0;
	font-family: "Exo 2", sans-serif;
	font-size: 1rem;
	color: var(--textColor);
}

a,
a:focus,
a:visited,
a:active {
	text-decoration: none;
	color: inherit;
}

/* img {
	width: 100%;
} */
.p-component {
	font-family: inherit;
	font-size: 1rem;
	font-weight: normal;
}

.error-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
	text-align: center;
	background-color: var(--primary-color);
}

.error-container {
	width: 100%;
	max-width: 1320px;
	--bs-gutter-x: 1.5rem;
	--bs-gutter-y: 0;
	padding-right: calc(var(--bs-gutter-x) * 0.5);
	padding-left: calc(var(--bs-gutter-x) * 0.5);
	margin-right: auto;
	margin-left: auto;
}

.text-key-val-grid {
	display: flex;
	align-items: self-start;
	justify-content: space-between;
	border-bottom: 1px solid #dedede;
	margin: 0 20px;
	padding: 10px 0;
	line-height: 30px;
}

.text-key-val-grid.no-border {
	border-bottom: none;
}

.text-key-val-grid .lable {
	color: var(--muteColor);
	white-space: nowrap;
	text-align: right;
}

.text-key-val-grid .value {
	font-weight: 500;
	color: var(--defaultColor);
	text-align: right;
}

.insure-amt {
	font-size: 25px;
	font-weight: 500;
	text-align: right;
	margin-top: 1rem;
}

/*============= Color Systems ============*/
.secondary-bg {
	background-color: var(--secondary-color);
}

.text-seccess {
	color: var(--success-primary);
}

.text-danger {
	color: var(--danger-primary);
}

/*============= Grid systems =============*/
.conatiner {
	width: 100%;
	max-width: 1320px;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
	padding-top: 0.5rem;
	margin-right: auto;
	margin-left: auto;
}

.app-wrapper {
	width: var(--w-full);
	position: relative;
}

.h-screen {
	height: var(--h-screen);
}

.w-full {
	width: var(--w-full);
}

.position-relative {
	position: relative;
}

.p-tabview.table-tab .p-tabview-panels {
	padding: 0;
	border: none;
	background: transparent;
}

.p-tabview.table-tab .p-tabview-nav {
	border: none;
	padding: 0;
}

.p-tabview .p-tabview-panels {
	background: var(--secondary-gama);
	border: 2px solid #dee2e6;
	border-top: none;
}

.p-tabview .p-tabview-nav li {
	margin-right: 2px;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
	background: var(--primary-color);
	color: var(--white);
	padding: 0.8rem 2rem;
	font-weight: 600;
	border-top-right-radius: 15px;
	border-top-left-radius: 15px;
}

.p-tabview.table-tab .p-tabview-nav li .p-tabview-nav-link {
	background: var(--widgetcount-background);
	color: var(--defaultColor);
	padding: 0.8rem 2rem;
	font-weight: 600;
	border-top-right-radius: 15px;
	border-top-left-radius: 15px;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
	background: linear-gradient(180deg,
			var(--secondary-alpha) 46%,
			var(--secondary-gama));
	border-color: var(--secondary-gama);
	color: var(--primary-color);
}

.p-tabview.table-tab .p-tabview-nav li.p-highlight .p-tabview-nav-link {
	background: var(--primary-color);
	border-color: var(--primary-color);
	color: var(--white);
}

.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
	background: var(--primary-alpha);
	border-color: #dee2e6;
	color: var(--white);
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: none;
}

.p-divider.p-divider-horizontal {
	margin: 1rem 0;
	padding: 0 1.25rem;
}

.p-tabview .p-tabview-nav .p-tabview-ink-bar {
	background-color: transparent;
}

.p-tabview .p-tabview-nav {
	padding: 0 10px;
}

/*============== Button Style =============*/
.p-button {
	width: fit-content;
	color: var(--white);
	background: var(--primary-color);
	border: 1px solid var(--primary-color);
	/* border-radius: 0px; */
}

.auth-btn {
	padding: 0.5rem 0.5rem;
	font-size: 24px;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 4px;
}

.p-button.p-button-outlined {
	background-color: transparent;
	color: var(--primary-color);
	border: 1px solid;
}

.p-button.p-button-outlined:enabled:hover,
.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
	background: rgb(37 61 152 / 4%);
	color: var(--primary-color);
	border: 1px solid;
}

.primary-btn {
	background-color: var(--primary-color);
	color: var(--white);
}

.primary-btn:hover,
.primary-btn:focus,
.primary-btn:active {
	background-color: var(--primary-color);
	color: var(--white);
}

.primary-btn:disabled {
	background-color: var(--primary-color);
	color: var(--white);
}

.danger-btn {
	background-color: var(--danger-primary);
	border: 1px solid var(--danger-primary);
	color: var(--white);
}

.danger-btn:hover,
.danger-btn:focus,
.danger-btn:active {
	background-color: #d42902 !important;
	border: 1px solid #d42902 !important;
	color: var(--white) !important;
}

.danger-btn:disabled {
	background-color: var(--danger-secondary);
	border: 1px solid var(--danger-secondary) !important;
	color: var(--white);
}

.success-btn {
	background-color: var(--success-primary);
	border: 1px solid var(--success-primary);
	color: var(--white);
}

.success-btn:hover,
.success-btn:focus,
.success-btn:active {
	background-color: #08a356 !important;
	border: 1px solid #08a356 !important;
	color: var(--white) !important;
}

.success-btn:disabled {
	background-color: var(--success-secondary);
	border: 1px solid var(--success-secondary) !important;
	color: var(--white);
}

.warning-btn {
	background-color: var(--secondary-color);
	border: 1px solid var(--secondary-color);
	color: var(--white);
}

.warning-btn:hover,
.warning-btn:focus,
.warning-btn:active {
	background-color: var(--secondary-alpha) !important;
	border: 1px solid var(--secondary-alpha) !important;
	color: var(--white) !important;
}

.warning-btn:disabled {
	background-color: var(--secondary-bita);
	border: 1px solid var(--secondary-bita) !important;
	color: var(--white);
}

.alert-btn {
	background-color: #da6000;
	border: 1px solid #da6000;
	color: var(--white);
}

.alert-btn:hover,
.alert-btn:focus,
.alert-btn:active {
	background-color: #d25c00 !important;
	border: 1px solid #d25c00 !important;
	color: var(--white) !important;
}

.alert-btn:disabled {
	background-color: #ff9b4c;
	border: 1px solid #ff9b4c !important;
	color: var(--white);
}

.disabled-btn,
.disabled-btn:hover {
	background: var(--white) !important;
	color: var(--table-text-color) !important;
	border-color: #ced4da !important;
}

.icon-btn {
	width: 26px !important;
	height: 26px;
	border-radius: 4px;
	padding: 0 !important;
	margin: 0;
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 20px;
	cursor: pointer;
}

.p-button {
	font-size: 0.9rem;
	/* border-radius: 0px; */
}

.p-button:focus {
	box-shadow: none;
}

.p-dialog .p-dialog-footer button {
	margin: 0;
}

.p-button.p-button-icon-only {
	padding: 0.75rem 0.75rem;
}

/*============= Modal & Form Style ===============*/
.p-dialog .p-dialog-header {
	padding: 1rem 1.5rem;
}

.modal-form-grid {
	background-color: var(--secondary-gama);
	padding: 15px;
	border: 1px solid #dedede;
	border-radius: 5px;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
	border-color: var(--primary-color);
	background: var(--primary-color);
}

.contact-grid-card {
	position: relative;
	padding: 25px 15px;
	background: #ffffff78;
	border-radius: 10px;
	border: 1px solid #dedede;
	margin-bottom: 15px;
}

.floating-icon-btn {
	position: absolute;
	right: 10px;
	top: 10px;
	cursor: pointer;
	color: var(--danger-primary);
}

.floating-icon-btn i {
	font-size: 1.5rem;
}

/*============= Status style ==============*/
.status {
	display: inline-block;
	padding: 2px 5px;
	min-width: 108px;
	font-size: 12px;
	line-height: 18px;
	font-weight: 500;
	text-align: center;
	border-radius: 4px;
}

.sq-status {
	display: inline-flex;
	width: 25px;
	height: 25px;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
}

.status-success {
	color: var(--success-primary);
	background-color: var(--success-secondary);
}

.status-danger {
	color: var(--danger-primary);
	background-color: var(--danger-secondary);
}

.status-danger-deep {
	color: var(--white);
	background-color: var(--danger-primary);
}

.status-mute {
	color: var(--white);
	background-color: var(--muteColor);
}

.status-primary {
	color: var(--white);
	background-color: var(--primary-color);
}

.status-warning {
	color: var(--widgetcount-text-color);
	background-color: var(--secondary-alpha);
}

.status-ban {
	color: var(--white);
	background-color: var(--primary-bita);
}

/*============== Auth Input ===============*/
.auth-input {
	display: block;
	width: 100%;
	font-size: 22px;
	line-height: 1;
	font-weight: 400;
	padding: 11px 11px 11px 45px;
	border: none;
	color: var(--textColor);
	background-color: var(--white);
	background-clip: padding-box;
	appearance: none;
	border-radius: 8px;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.auth-input:focus {
	outline: none;
}

.auth-input::placeholder {
	color: var(--primary-color);
}

.p-inputnumber-input {
	width: 100%;
}

/*============== Auth Page ================*/
.auth-bg {
	width: 100%;
	position: absolute;
	z-index: 0;
	bottom: 0;
}

.auth-logo-grid {
	text-align: left;
	margin: 50px auto;
	height: 150px;
}

.auth-logo-grid img {
	max-height: 100%;
}

.auth-wrapper {
	width: var(--w-full);
	position: relative;
}

.auth-wrapper .auth-card {
	margin: 0 0 0 auto;
	width: 35%;
	background: rgba(255, 255, 255, 0.5);
	padding: 50px 50px 120px 50px;
	box-shadow: 7px 6px 13px 2px rgb(0 0 0 / 26%);
	border-radius: 8px;
	-webkit-backdrop-filter: blur(5px);
	backdrop-filter: blur(5px);
}

.auth-card h2 {
	font-weight: 600;
	line-height: 1.5;
	margin-bottom: 40px;
}

.auth-field .icon {
	position: absolute;
	top: 12px;
	left: 15px;
	font-size: 22px;
	color: var(--primary-color);
}

/*============= Side Nav =====================*/
.sidenav-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	/* padding: 50px 0px; */
	width: 90px;
	z-index: 999;
	height: var(--h-screen);
	background-color: var(--primary-color);
}

.sidenav-wrapper.opend {
	left: 0 !important;
}

.nav-list {
	margin: 0;
	padding: 20px 0;
	list-style: none;
	overflow-y: auto;
	height: var(--h-screen);
}

.nav-list li.nav-item {
	width: 100%;
	margin-bottom: 10px;
	text-align: center;
	cursor: pointer;
}

.nav-list li.nav-item:last-child {
	margin-bottom: 0;
}

.nav-list li.nav-item .nav-item-content {
	display: inline-block;
	width: 48px;
	height: 48px;
	font-size: 22px;
	padding: 13px;
	line-height: 1;
	border-radius: 50%;
	background: transparent;
	color: rgb(255 255 255 / 50%);
	transition: 0.1s ease-in;
}

.nav-list li.nav-item:hover .nav-item-content {
	background: rgb(255 255 255 / 10%);
	/* color: var(--secondary-color); */
	transition: 0.1s ease-in;
}

.nav-item-content.active {
	background: rgb(255 255 255 / 10%) !important;
	color: var(--secondary-color) !important;
}

.nav-list li.nav-item .sub-nav-list {
	text-align: left;
	cursor: default;
}

.sub-nav-list {
	width: 0px;
	height: 100%;
	position: absolute;
	left: 90px;
	top: 0;
	background: var(--secondary-color);
	overflow-x: hidden;
	overflow-y: auto;
	transition: 0.1s ease-in;
}

.nav-list li.nav-item:hover .sub-nav-list {
	width: 100%;
	min-width: 300px;
	transition: 0.1s ease-in;
}

.sub-nav-list h3.nav-title {
	margin: 0;
	padding: 10px 18px;
	font-size: 1.5rem;
	margin-bottom: 1rem;
	font-weight: 700;
	color: var(--primary-color);
}

ul.child-nav-list {
	padding: 0;
	list-style: none;
}

ul.child-nav-list li.nav-sub-item div,
ul.child-nav-list li.nav-sub-item a {
	padding: 10px 18px;
	margin-bottom: 5px;
	cursor: pointer;
}

ul.child-nav-list li.nav-sub-item a {
	display: block;
}

li.nav-sub-item .menu-name {
	color: var(--muteColor);
}

li.nav-sub-item .menu-name:hover,
li.nav-sub-item .menu-name.active {
	color: var(--defaultColor);
	transition: 0.1s linear;
}

li.nav-sub-item .menu-name {
	font-size: 1rem;
	font-weight: 700;
	text-decoration: none;
}

.nav-sub-item ul.menu-list {
	list-style: none;
	padding-left: 34px;
}

ul.menu-list li.menu-list-item {
	position: relative;
	margin-bottom: 5px;
}

ul.menu-list li.menu-list-item::before {
	content: "";
	width: 14px;
	height: 2px;
	position: absolute;
	top: 50%;
	left: -16px;
	background: rgba(28, 29, 34, 0.2);
}

li.menu-list-item a {
	padding: 10px 18px;
	display: block;
	font-size: 1rem;
	font-weight: 700;
	color: var(--muteColor);
	transition: 0.1s linear;
}

li.menu-list-item:hover a,
li.menu-list-item a.active {
	color: var(--defaultColor);
	background-color: var(--secondary-alpha);
	transition: 0.1s linear;
}

/*================ Header ===================*/
.header-wrapper {
	position: relative;
	padding: 15px 30px;
	width: var(--h-full);
	background-color: var(--white);
}

.app-logo {
	max-height: 50px;
}

.app-grid {
	position: relative;
	margin-left: 90px;
}

.left-header,
.right-header {
	display: flex;
	flex: 1;
	align-items: center;
}

.left-header {
	justify-content: flex-start;
}

.right-header {
	justify-content: flex-end;
}

.user-info {
	position: relative;
	display: flex;
	align-items: center;
	cursor: pointer;
}

.user-info .info {
	display: block;
	text-align: right;
	margin-right: 15px;
}

.user-info .info p {
	margin-bottom: 0;
	margin-top: 0;
	font-size: 1.2rem;
	font-weight: 600;
}

.user-info .info span {
	font-size: 0.8rem;
	font-weight: 500;
	line-height: 1;
}

.user-info .avater {
	position: relative;
	width: 3rem;
	height: 3rem;
	border: 2px solid var(--secondary-color);
	border-radius: 100%;
}

.user-info .avater img {
	width: 100%;
	border-radius: 50%;
}

.notification {
	position: relative;
	width: 50px;
	height: 50px;
	line-height: 1;
	text-align: center;
	padding: 10px;
	border-radius: 50%;
	cursor: pointer;
}

.in-mob-info {
	display: none;
	padding: 0.75rem 1.25rem;
	text-align: center;
	background: var(--primary-color);
	color: var(--white);
	border-radius: 6px 6px 0 0;
}

.in-mob-info span {
	display: block;
}

.p-menuitem-link svg {
	margin-right: 0.5rem;
}

.p-menu .p-menuitem-link:not(.p-disabled):hover {
	background: var(--secondary-gama);
}

.app-content {
	min-height: calc(100vh - 80px);
	padding: 15px 30px;
	background: linear-gradient(180deg, #fff7cc 0%, rgba(245, 251, 255, 0) 25%);
}

.app-breadcrum {
	padding: 0;
	background: transparent;
	border: none;
}

/*============== Data Table ==================*/
.p-datatable .p-datatable-thead>tr>th,
.p-treetable .p-treetable-thead>tr>th {
	text-align: left;
	padding: 0.6rem 1rem;
	border: none;
	border-width: 0;
	font-size: 0.75rem;
	text-transform: capitalize;
	font-weight: 500;
	color: var(--secondary-color);
	background: var(--primary-color);
	transition: box-shadow 0.2s;
}

.p-datatable .p-datatable-tbody>tr>td,
.p-treetable .p-treetable-tbody>tr>td {
	border: none;
	border-width: 0 0 0 0;
	padding: 0.5rem 1rem;
	font-size: 13px;
}

.p-datatable .p-datatable-tbody>tr,
.p-treetable .p-treetable-tbody>tr {
	color: var(--table-text-color);
	transition: box-shadow 0.2s;
}

.p-datatable .p-datatable-tbody>tr:nth-child(odd),
.p-treetable .p-treetable-tbody>tr:nth-child(odd) {
	background: var(--white);
}

.p-datatable .p-datatable-tbody>tr:nth-child(even),
.p-datatable .p-datatable-tbody>tr.p-datatable-row-expansion,
.p-treetable .p-treetable-tbody>tr:nth-child(even) {
	background: #f2f2f2;
}

.p-datatable .p-column-header-content {
	display: inline-block;
	align-items: center;
}

.p-datatable.p-datatable-selectable .p-datatable-tbody>tr.p-selectable-row:not(.p-highlight):not(.p-datatable-emptymessage):hover,
.p-datatable .p-datatable-tbody>tr.p-highlight,
.p-datatable .p-datatable-tbody>tr:not(.p-datatable-row-expansion):hover,
.p-datatable.p-datatable-selectable .p-datatable-tbody>tr.p-datatable-row:not(.p-highlight):not(.p-datatable-emptymessage):hover,
.p-datatable .p-datatable-tbody>tr.p-highlight,
.p-datatable .p-datatable-tbody>tr:not(.p-datatable-row-expansion):hover {
	background: var(--secondary-color);
	color: var(--table-text-color);
}

.pagination-btn {
	padding: 0;
	width: 30px;
	height: 30px;
	background: var(--primary-color);
	color: var(--secondary-color);
	border: none;
	border-radius: 10px;
	text-align: center;
	line-height: 1;
	cursor: pointer;
}

.pagination-btn:hover {
	background: var(--secondary-color);
	color: var(--primary-color);
}

.pagination-btn:disabled,
.pagination-btn:disabled:hover {
	cursor: not-allowed;
	background: var(--primary-gama);
	color: var(--secondary-gama);
}

.sort-icon {
	width: 0;
	height: 0;
	border: 6px solid transparent;
}

.sort-icon.asc {
	border-bottom-color: var(--secondary-color);
	margin-bottom: 0.3rem;
}

.sort-icon.desc {
	border-top-color: var(--secondary-color);
}

.sort-icon.asc.active {
	border-bottom-color: var(--secondary-gama);
}

.sort-icon.desc.active {
	border-top-color: var(--secondary-gama);
}

/*============== Form Elemensts ==============*/
.field {
	margin-bottom: 0rem;
}

.p-inputtext {
	font-family: inherit;
	color: var(--defaultColor);
	background: var(--white);
	padding: 0.7rem 0.75rem;
	border: 1px solid #ced4da;
	/* border-radius: 0px; */
}

.p-inputtext:enabled:hover {
	border-color: var(--primary-color);
}

.p-inputtext:enabled:focus {
	box-shadow: 0 0 0 0.2rem var(--primary-gita);
	border-color: var(--primary-color);
}

.p-inputtext.search-field:focus {
	box-shadow: none;
}

.p-dropdown {
	background: var(--white);
	border: 1px solid #ced4da;
	border-radius: 0px;
}

.p-dropdown:not(.p-disabled):hover {
	border-color: var(--primary-color);
}

.p-dropdown:not(.p-disabled).p-focus {
	box-shadow: 0 0 0 0.2rem var(--primary-gita);
	border-color: var(--primary-color);
}

.p-autocomplete-input {
	width: 100%;
}

.p-inputswitch .p-inputswitch-slider {
	background: var(--danger-primary);
}

.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
	background: var(--danger-primary);
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
	background: var(--success-primary);
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
	background: var(--success-primary);
}

.p-checkbox .p-checkbox-box.p-highlight {
	border-color: var(--primary-color);
	background: var(--primary-color);
}

/*=============== Check Box ==================*/
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
	border-color: var(--primary-bita);
}

.title-bar {
	min-height: 122px;
	max-height: 122px;
	background: var(--grident-background);
	border-radius: 10px 10px 0px 0px;
	text-align: center;
}

.title-bar h3 {
	font-size: 30px;
	color: var(--white);
}

.title-bar p {
	font-size: 21px;
	color: var(--secondary-color);
}

.mainwidgetbox {
	margin: -23px auto 0;
}

.mainwidgetbox .widgetbox {
	font-size: 16px;
}

.mainwidgetbox .widgetbox .widgetcount {
	width: 44px;
	height: 44px;
	background: var(--widgetcount-background);
	border: 1px solid #ffffff;
	border-radius: 50%;
}

.mainwidgetbox .widgetbox .widgetcount.active {
	background: var(--secondary-color);
}

.mainwidgetbox .widgetbox .widgetcount.success {
	background: var(--success-primary);
}

.mainwidgetbox .widgetbox .widgetcount p {
	font-size: 20px;
	color: var(--widgetcount-text-color);
	font-weight: 700;
	margin: 0;
	line-height: 1;
}

.mainwidgetbox .widgetbox .widgetcount.success p {
	color: var(--white);
}

/* ============== financial-institutions ============= */
.generalbgbox {
	background: var(--secondary-gama);
}

.generalbgbox2 {
	background: var(--secondary-alpha);
}

/*--------- Allocation --------*/
.p-tabview-nav-content .p-tabview-nav {
	background-color: unset;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
	color: #f1dd40;
}

.tablegrid td,
.tablegrid th {
	border: 1px solid #ccc;
	padding: 10px 18px;
	margin: 0;
}

.tablegrid th {
	background-color: var(--primary-color);
	color: var(--secondary-alpha);
}

.tablegrid {
	width: 100%;
	border-collapse: collapse;
}

.customtabview .p-tabview-nav,
.customtabview .p-tabview-panels {
	border: none;
	padding: 0;
}

/*--------spinner------*/
.loader_spinner {
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 9999999;
	display: flex;
	align-items: center;
	justify-content: center;
	backdrop-filter: blur(2px);
	flex-direction: column;
	top: 0;
	left: 0;
	background-color: #1817173b;
}

.switch_toggle {
	width: 40px;
	height: 20px;
}

.switch_toggle span:before {
	width: 16px !important;
	height: 16px !important;
	margin-top: -8px !important;
	left: 1px !important;
}

/*---- data_filter  ----*/
.background-secondary {
	background-color: var(--secondary-color);
}

/*----- finance --*/
.institutePanel .p-panel-header {
	border-radius: 10px;
	box-shadow: 0 0 10px 0 #33333310;
}

.institutePanel .p-panel-content {
	border-radius: 10px;
	box-shadow: 0 0 10px 0 #33333310;
}

.product-card.p-card {
	box-shadow: 0px 0px 1px var(--defaultColor);
	cursor: pointer;
}

.product-card.p-card.active,
.product-card.p-card:hover {
	background-color: var(--secondary-alpha);
}

/* css add by arunava */
.c-no-product-w {
	padding-top: 100px;
	justify-content: center;
}

.c-no-product-in {
	background-color: #fff;
	padding: 50px;
	padding-top: 30px;
	border: 3px dashed #ccc;
	border-radius: 11px;
}

.c-no-product-in h3 {
	text-align: center;
	font-weight: bold;
	font-size: 30px;
	color: #253d98;
	margin-bottom: 50px;
}

.c-no-product-btn {
	background: #f1dd40;
	width: 90px;
	height: 90px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
	position: relative;
	border: none;
	animation: ripple 0.9s linear infinite;
}

.c-no-product-btn i {
	font-size: 30px;
	color: #000;
}

@keyframes ripple {
	0% {
		box-shadow: 0 0 0 0 rgba(241, 221, 64, 0.3),
			0 0 0 12px rgba(241, 221, 64, 0.3),
			0 0 0 20px rgba(241, 221, 64, 0.3),
			0 0 0 28px rgba(241, 221, 64, 0.3);
	}

	100% {
		box-shadow: 0 0 0 12px rgba(241, 221, 64, 0.3),
			0 0 0 20px rgba(241, 221, 64, 0.3),
			0 0 0 28px rgba(241, 221, 64, 0.3),
			0 0 0 40px rgba(241, 221, 64, 0.3);
	}
}

.product-list {
	display: flex;
	justify-content: space-between;
	padding: 10px;
	border: 1px solid #bcbcbc;
	border-radius: 4px;
	margin-bottom: 10px;
	cursor: pointer;
	font-weight: 500;
	background: #fff;
	transition: 0.5s;
}

.product-list:hover {
	background-color: var(--light-blue);
}

.product-list.active {
	background-color: var(--light-green);
	color: var(--success-primary);
}

.product-list .pi-plus {
	color: var(--primary-color);
}

.product-scroll-wraper {
	height: calc(100vh - 15%);
	overflow-y: auto;
	padding: 3px 10px;
}

/* New relesed 03-04-23R */
.template .p-button i {
	line-height: 2.25rem;
}

:root {
	--indigo-400: #8183f4;
	--indigo-600: #5457cd;
	--indigo-700: #4547a9;
}

.template .p-button.facebook {
	background: linear-gradient(to left,
			var(--indigo-600) 50%,
			var(--indigo-700) 50%);
	background-size: 200% 100%;
	background-position: right bottom;
	transition: background-position 0.5s ease-out;
	color: #fff;
	/* border-color: var(--indigo-700); */
	/* width: 35%; */
	border: none !important;
}

.template .p-button.facebook:hover {
	background-position: left bottom;
}

.template .p-button.facebook i {
	background-color: var(--indigo-700);
}

.template .p-button.facebook:focus {
	box-shadow: 0 0 0 1px var(--indigo-400);
}

.p-accordion .p-accordion-header .p-accordion-header-link {
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
	padding: 1rem;
	border: 1px solid #dee2e6;
	color: #6c757d;
	background: #f8f9fa;
	font-weight: 700;
	border-radius: 6px;
	transition: box-shadow 0.2s;
}

.p-accordion .p-accordion-content {
	padding: 1rem;
}

.p-divider .p-divider-content {
	/* background-color: var(--secondary-gama); */
}

.add-more-btn {
	width: 100%;
	padding: 10px;
	color: var(--primary-color);
	background: var(--blue-100);
	border: 1px dashed var(--primary-color);
	border-radius: 4px;
	cursor: pointer;
}

/*=============== Timeline Status ==================*/
.timeline-demo .p-card {
	border: 1px solid #c7c7c7;
	box-shadow: none;
}

.timeline-demo .custom-marker {
	display: flex;
	width: 45px;
	height: 45px;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	z-index: 1;
}

.timeline-demo .p-timeline-event-content,
.timeline-demo .p-timeline-event-opposite {
	line-height: 1;
}

.listBox {
	display: flex;
	justify-content: flex-start;
	align-items: center;

	padding-top: 12px;
}

.list-box-grid {
	background-color: #fbfbfb;
	padding: 5px 20px;
	margin: 10px 0;
	border: 1px solid #d1cece;
	border-radius: 10px;
}

/* .list-box-grid:nth-child(2) .list-box-container{
border-top: none;
}
.list-box-container {
border-top: 1px dashed #ccc;
} */
/* .grid .col-12:nth-child(2) .listBox {
	
} */

.listBox h5 {
	margin: 0;
}

.listBox i {
	font-size: 1.5rem;
	margin-right: 15px;
}

.listBox span small {
	font-size: 12px;
}

@media screen and (max-width: 960px) {
	.timeline-demo .customized-timeline .p-timeline-event:nth-child(even) {
		flex-direction: row !important;
	}

	.timeline-demo .customized-timeline .p-timeline-event:nth-child(even) .p-timeline-event-content {
		text-align: left !important;
	}

	.timeline-demo .customized-timeline .p-timeline-event-opposite {
		flex: 0;
	}

	.timeline-demo .customized-timeline .p-card {
		margin-top: 1rem;
	}
}

/*=============== Responsive ==================*/

/*=============== Responsive ==================*/
@media screen and (max-width: 1600px) {
	.auth-logo-grid {
		margin: 15px auto;
		height: 120px;
	}
}

@media screen and (max-width: 1024px) {
	.auth-logo-grid {
		text-align: center;
		margin: 50px auto;
		height: 170px;
	}

	.auth-wrapper .auth-card {
		margin: 0 auto 0 auto;
		width: 56%;
	}
}

@media screen and (max-width: 900px) {
	.auth-logo-grid {
		height: 120px;
	}

	.auth-wrapper .auth-card {
		margin: 0 auto 0 auto;
		width: 70%;
	}
}

@media screen and (max-width: 600px) {
	.auth-wrapper .auth-card {
		width: 90%;
		padding: 50px 20px 50px 20px;
	}

	.auth-input,
	.auth-btn,
	.auth-field .icon {
		font-size: 16px;
	}

	.sidenav-wrapper {
		left: -90px;
		top: 70px;
	}

	.app-grid {
		margin-left: 0;
	}

	.header-wrapper {
		padding: 10px 10px;
	}

	.user-info .info {
		display: none;
	}

	.in-mob-info {
		display: block;
	}
}

@media screen and (max-width: 380px) {
	.auth-logo-grid {
		margin: 30px auto;
		height: 90px;
	}
}

@media screen and (max-width: 360px) {
	.auth-logo-grid {
		margin: 15px auto;
		height: 60px;
	}

	.auth-wrapper .auth-card {
		width: 90%;
		padding: 20px 20px 20px 20px;
	}

	.nav-list li.nav-item:hover .sub-nav-list {
		min-width: 220px;
	}
}

.form-disable {
	pointer-events: none;
}

.c-download-pw ol {
	padding-left: 16px;
}

.c-download-pw ol li {
	margin-bottom: 10px;
}

.c-download-btn {
	display: flex;
	flex-wrap: wrap;
	margin: 40px 0;
	align-items: center;
}

.c-download-btn .c-download-text {
	margin-right: 30px;
}

.c-download-btn .c-excel-icon i {
	font-size: 40px;
	color: #1d6f42;
}

.c-download-btn .p-button {
	background: #253d98 !important;
	border-radius: 8px !important;
	margin-left: 8px;
}

.c-download-btn .p-button span {
	color: #fff;
}

/* Institute details box design css */
.customDetilsBox {
	padding-left: 10px;
}

.customDetilsBox .p-card {
	box-shadow: 0px 0px 1px var(--defaultColor);
}

.customDetilsBox .p-card-content {
	padding: 0;
}

.avtarIcon {
	width: 120px;
	height: 120px;
	border-radius: 50%;
	background: rgba(255, 234, 99, 0.15);
	border: 1px solid rgba(204, 204, 204, 0.8);
	box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 30px;
}

.c-card-box .col-12 {
	width: 20%;
}

.c-card-radio {
	display: flex;
}

.c-card-radio label {
	margin-left: 8px;
	font-weight: bold;
	color: var(--primary-color);
}

.avtarIcon img {
	width: 50%;
}

.detailsBox h2 {
	margin: 0;
}

.detailsBox p {
	margin-bottom: 0;
}

.c-card-box-in {
	border: 1px solid #dee2e6;
	background: #f8f9fa;
	border-radius: 10px;
	padding: 15px;
}

.c-card-box-in ul {
	list-style-type: none;
	display: block;
	margin: 0;
	padding: 0;
	margin-top: 15px;
}

.c-card-box-in ul li {
	display: block;
	padding: 10px;
	padding-left: 18px;
	border-bottom: 1px solid #dee2e6;
	position: relative;
}

.c-card-box-in ul li:last-child {
	border: none;
}

.c-card-box-in ul li:after {
	width: 5px;
	height: 5px;
	background: var(--primary-color);
	content: "";
	position: absolute;
	left: 0;
	top: 17px;
	border-radius: 50%;
}

.c-box-w {
	border-radius: 10px;
	border: 1px solid #eee;
	padding: 20px;
	text-align: center;
}

.c-box-w i {
	font-size: 40px;
	color: #253d98;
}

.c-box-w h4 {
	margin-top: 15px !important;
	font-size: 20px;
	font-weight: bold;
	margin-bottom: 7px !important;
}

.c-box-w p {
	margin: 0;
}

.c-round-text {
	width: 45px;
	border-radius: 50%;
	border: 2px solid #253d98;
	height: 45px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	line-height: 100%;
	color: #253d98;
	margin: 0;
}

.pageBrake {
	page-break-before: always;
}

.listed {
	list-style: none;
}

.listed li {
	line-height: 28px;
}

.c-image-cat .image-container {
	border: 1px solid #253d98;

	width: 40px;
	display: inline-block;
	height: 40px;
	border-radius: 50%;
	position: relative;
}

.c-image-cat .image-container img {
	height: 100%;
	width: 100%;
	object-fit: cover;
	border-radius: 50%;
}

.c-product-item {
	text-align: center;
	padding: 10px 0;
	border-bottom: 1px solid #ccc;
	margin: 0;
}

.c-image-cat .product-list-detail h5 {
	font-size: 16px;
	margin-top: 10px;
	margin-left: 10px;
}

.c-image-cat .p-button {
	background: #4f46e5 !important;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	color: #fff !important;
}

.c-image-cat .product-list-action {
	margin-left: auto;
}

.c-image-cat {
	padding: 10px;
	margin-bottom: 10px;
	background: #e9ecef;
	border-color: #dee2e6;
}

/* .p-listbox .p-listbox-list .p-listbox-item {
	background-color: transparent !important;
}
.p-listbox .p-listbox-list .p-listbox-item {
	padding: 0px 10px;
	padding-bottom: 10px;
} */

.c-list-box-w .c-list-box-img {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	display: inline-block;
}

.c-list-box-w .c-list-box-img img {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	object-fit: cover;
}

.c-list-box-w .p-button-icon-only {
	transform: scale(0.9);
}

.c-list-box-w {
	border: 2px dashed #ccc;
	background-color: #cdccff3b;
}

.c-small-card {
	background: #ffffff;
	background: #ffffff url(assets/images/offer-bg.png) no-repeat;
	background-position: right bottom;
	border-radius: 12px;
}

.c-small-card .p-card-content {
	padding: 0;
}

.c-circle {
	width: 40px;
	height: 40px;
	border-radius: 50%;
}

.c-bagdeBtn {
	min-width: 78px;
	height: 22px;
	border-radius: 30px;
	color: #fff;
	font-size: 14px;
	text-transform: capitalize;
	line-height: normal;
}

.dashboard-p .p-divider .p-divider-content {
	background-color: unset;
}

.c-table-nw {
	width: 100%;
	border-collapse: collapse;
	min-width: 100%;
}

.c-table-nw tr th {
	padding: 0.6rem 1rem;
	border: none;
	border-width: 0;
	font-size: 0.75rem;
	text-transform: capitalize;
	font-weight: 500;
	color: var(--secondary-color);
	background: var(--primary-color);
	text-align: left;
}

.c-table-nw tr td {
	padding: 0.6rem 1rem;
	border: none;
	border-width: 0;
	font-size: 0.75rem;
	background: #eee;
	text-align: center;
	text-align: left;
}

.c-table-nw tbody tr:nth-child(2n + 2) td {
	background: #fff;
}

.c-table-nw tbody tr td input.p-inputtext {
	padding: 6px;
	height: auto;
}

.w-100 {
	width: 100%;
}

.c-include-box {
	width: 100%;
	background: #fff;
	position: relative;
	padding: 10px 15px 10px 30px;
	-webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.18);
	border-radius: 7px;
	-moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.18);
	box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.18);
	border: 1px soid #eee;
}

.c-include-box-in {
	margin-left: 50px;
}

.c-products-list {
	align-items: center;
	display: inline-flex;
	flex-wrap: wrap;
	border-bottom: 2px dotted var(--indigo-700);
}

.c-products-list span.bg-indigo-100 {
	margin-right: 10px;
	margin-bottom: 12px;
}

.c-include-box p {
	font-weight: bold;
}

.c-include-box select,
.c-include-box input {
	padding: 8px;
	border: 1px solid #ccc;
	line-height: 100%;
	border-radius: 4px;
}

.c-include-box .p-button {
	margin-bottom: 12px;
}

.ql-toolbar.ql-snow {
	border-radius: 6px 6px 0 0;
}

.ql-container.ql-snow {
	border-radius: 0 0 6px 6px;
}

/* User box agent commision */
.userLIst {
	margin: 0;
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.userLIst li {
	list-style: none;
	color: #7c7b7b;
}

.userLIst li strong {
	color: #253d98;
}

.stValue span.status-primary {
	color: #ffffff !important;
}

.tabNav .p-tabview-panels {
	border: none;
}

.c-agent-hw strong {
	color: #080955;
}

.dashboard-container .p-tabview-nav {
	border: none;
}

.dashboard-container .p-tabview .p-tabview-panels {
	background-color: unset;
}

/* css add by dashbord 20-11-2023 */
.c-box-dw {
	background-color: #fff;
	padding: 20px 15px;
	border-radius: 12px;
}

.c-box-dw-grid .col-12 {
	width: 20%;
}

.c-box-dw {
	text-align: center;
	box-shadow: -8px 1px 22px -16px rgba(0, 0, 0, 0.14);
	-webkit-box-shadow: -8px 1px 22px -16px rgba(0, 0, 0, 0.14);
	-moz-box-shadow: -8px 1px 22px -16px rgba(0, 0, 0, 0.14);
	border: 1px solid #253d98;
	border-radius: 12px;
	min-height: 100%;
}

.c-box-dw h3 {
	font-size: 20px;
	font-weight: bold;
	color: #253d98;
	margin: 0;
	line-height: 100%;
}

.c-box-dw h2 {
	color: #000;
	font-size: 13px;
	font-weight: 600;
	margin: 0;
	margin-top: 10px;
	line-height: normal;
}

/* .c-box-dw-grid .col-12:nth-child(2n + 2) .c-box-dw {
	background: #eee;
} */

.c-box-dw h3 sub {
	font-size: 13px;
	vertical-align: text-bottom;
}

.c-agent-box-1 {
	background: #fff;
	border-radius: 12px;
	padding: 10px;
	box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
	-webkit-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
	-moz-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
	border: 1px solid #eee;
}

.c-agent-box-2 {
	background: #fff;
	border-radius: 12px;
	padding: 10x;
	box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
	-webkit-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
	-moz-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
	border: 1px solid #eee;
}

.c-chart-w-full {
	width: 100%;
}

.c-search-form-box {
	padding: 10px;
}

.c-search-form-box .md\:col-3 {
	padding-top: 0;
	padding-bottom: 0;
	margin-bottom: 5px;
}

.c-search-form-chackbox label.w-full {
	white-space: nowrap;
	margin-left: 10px;
	order: 2;
}

.c-search-form-chackbox {
	/* background: #fff; */
	display: inline-flex !important;
	width: auto;
	padding: 0;
	border-radius: 6px;
}

.c-search-form-chackbox .flex.align-items-center {
	padding: 0 10px;
	border-right: 1px solid #ccc;
}

.c-search-form-chackbox .flex.align-items-center:last-child {
	padding-right: 0;
	border: none;
}

.c-search-form-chackbox .flex.align-items-center:first-child {
	padding-left: 0;
}

@media only screen and (max-width: 767px) {
	.c-box-dw-grid .col-12 {
		width: 50%;
	}
}

@media only screen and (max-width: 399px) {
	.c-box-dw-grid .col-12 {
		width: 100%;
	}
}

.c-user-dw p {
	font-size: 13px;
}

.c-user-dw-box {
	padding: 10px 0;
	border-bottom: 1px dashed #ccc;
}

.c-user-dw .text-primary {
	margin-bottom: 5px !important;
}

.c-no-data-found {
	text-align: center;
}

.c-no-data-found i {
	font-size: 50px;
	text-align: center;
}

.c-no-data-found h2 {
	text-align: center;
	font-size: 18px;
	font-weight: bold;
	color: #080955;
}

.c-agent-form {
	background: #eee;
	padding: 15px;
	margin-top: 15px;
	border: 1px solid #ced4da;
}

.c-agent-form .col-12 {
	padding: 0;
	margin-bottom: 10px;
}

.c-agent-form .p-inputtext {
	padding: 8px;
}

.c-table-design-w {
	overflow: auto;
}

.c-table-design-in {
	display: flex;
	border: 1px solid #eee;
}

.c-table-design-heading ul {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.c-table-design-heading ul li {
	display: block;
	padding: 10px 15px;
	font-weight: bold;
	color: var(--primary-color);
	font-size: 14px;

	white-space: nowrap;
}

.c-table-design-list {
	display: flex;
}

.c-table-design-list-box ul li:nth-child(2n + 2),
.c-table-design-heading ul li:nth-child(2n + 2) {
	background: #eee;
}

.c-table-design-list-box li:first-child,
.c-table-design-heading ul li:first-child {
	color: var(--secondary-color);
	background: var(--primary-color);
}

.c-table-design-list ul {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.c-table-design-list ul li {
	display: block;
	padding: 10px 15px;
	font-size: 14px;

	white-space: nowrap;
}

.c-table-design-heading ul li:last-child {
	border: none;
}

.c-user-activity-info {
	display: flex;
	align-items: center;
}

.c-user-activity-info span {
	width: 90%;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.c-search-list-radio .flex.align-items-center {
	padding: 0 5px;
	padding-top: 5px;
	position: relative;
	top: 6px;
}

.cr-agentDialog {
	border: 1px solid #c3c3c3;
	padding: 10px;
	border-radius: 4px;
}

.cr-agentDialog h4 {
	margin: 0;
	line-height: 30px;
}

.cr-dialog .p-dialog-content {
	padding: 0px 15px 20px;
}

.cr-reminder p {
	margin: 0;
	/* background: #ff4081;
	color: #080955; */
	font-size: 13px;
	/* padding: 0px 9px 1px;
	border-radius: 25px; */
}

/* .cr-buttonCard {overflow-x: auto;padding: 0; margin: 0 45px;} */
.cr-buttonCard::-webkit-scrollbar {
	display: none;
}

.cr-buttonCard .p-button {
	overflow: visible;
	white-space: nowrap;
}

.cr-buttonCard .p-button:hover,
.cr-buttonCard .p-button.activeBtn {
	background: var(--primary-color) !important;
	border: 1px solid var(--primary-color) !important;
	color: #ffffff !important;
}

.cr-buttonCard .p-button:first-child {
	margin-left: auto;
}

.cr-buttonCard .p-button:last-child {
	margin-right: auto;
}

.cr-groupBtn {
	position: relative;
}

.cr-arrowBtn .prev {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
}

.cr-arrowBtn .next {
	position: absolute;
	top: 50%;
	right: -6px;
	transform: translateY(-50%);
}

.c-tab-btn-w .p-button {
	margin-right: 10px !important;
}

/* .c-tab-btn-w {
	margin-bottom: 10px;
} */
@media screen and (min-width: 992px) {
	.c-user-dw-new {
		display: flex;
		flex-wrap: wrap;
	}

	.c-user-dw-new .c-user-dw-box {
		width: 33.33%;
		flex: 0 0 auto;
	}
}

@media screen and (max-width: 991px) {
	.c-box-dw-grid .col-12 {
		width: 50%;
	}

	/* .c-agent-lead-w .flex {
		display: block !important;
	} */
	.c-agent-lead-w .p-buttonset {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: wrap;
		gap: 0.5rem !important;
		margin-bottom: 10px;
	}

	.c-agent-lead-w .p-button {
		/* width: 100%; */
		/* margin-bottom: 10px; */
		border: 1px solid #ccc !important;
		border-radius: 12px !important;
		font-size: 11px;
		padding: 10px 12px 10px;
		line-height: normal;
		min-width: 100px;
		min-height: 43px;
	}

	/* .c-agent-lead-w .p-dropdown {
		margin-bottom: 10px;
		width: 100%;
	} */
	.c-agent-lead-w .c-btn-height {
		max-height: 43px;
		min-width: auto;
		padding: 0;
	}

	.c-agent-lead-w .c-w-full {
		width: 100%;
		border-top: 1px solid #ccc;
		padding-top: 10px;
	}

	.c-chart-w-full {
		width: 200% !important;
	}

	.c-agent-info-new {
		padding: 0 !important;
	}

	.c-agent-info-new-name {
		display: block !important;
		margin-top: 20px;
	}

	.c-agent-info-new-name h1 {
		font-size: 18px;
	}

	.c-agent-info-new-name small {
		margin: 0 !important;
		display: block;
		margin-top: 10px !important;
	}

	.c-agent-info-profile .p-selectbutton {
		display: flex;
		flex-wrap: wrap;
	}

	.c-agent-info-profile .p-selectbutton .p-button {
		width: calc(50% - 10px);
		flex: 0 0 auto;
		padding: 10px;
		border: 1px solid #ccc;
		border-radius: 12px !important;
		margin-bottom: 10px;
		margin-left: 5px;
		margin-right: 5px;
	}

	.c-agent-profile-hw {
		display: block !important;
	}

	.c-agent-profile-hw .flex {
		margin-bottom: 5px !important;
	}

	.c-agent-profile-hw .p-dropdown {
		width: 100% !important;
	}

	.c-prfile-chat {
		overflow-x: auto;
	}

	.c-prfile-chat .c-prfile-chat-in {
		width: 200% !important;
	}

	.c-agent-image img {
		width: 200px !important;
		object-fit: cover;
		object-position: top left;
		height: 200px;
		border-radius: 50%;
		overflow: hidden;
		margin: 0 auto;
	}

	.c-agent-image {
		display: flex;
		justify-content: center;
	}
}

.p-tabview-scrollable .p-tabview-nav-btn {
	border-radius: 50% !important;
	width: 48px;
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.cr-tabView .p-tabview .p-tabview-panels {
	padding: 0px;
	border: none;
	background: none;
}

.cr-tabView .p-tabview .p-tabview-nav {
	border: 0;
	padding: 0;
}

.cr-tabView .p-tabview .p-tabview-panels .cr-buttonCard {
	padding: 0;
}

.cr-tabView .p-tabview-nav-container {
	padding: 0 50px 10px;
}

.cr-tabView .p-tabview-nav li:first-child {
	margin-left: auto;
}

.cr-tabView .p-tabview-nav li:nth-last-child(2) {
	margin-right: auto;
}

.cr-tabView .p-tabview-panels {
	margin-top: 8px;
}

.cr-finalIns .contact-grid-card {
	padding: 10px 8px;
}

.cr-finalIns .floating-icon-btn {
	top: 0;
	right: 0;
}

.cr-finInsFrm .p-divider .p-divider-content {
	background-color: var(--secondary-gama);
}

.cr-bankBox {
	border: 1px solid #eee;
	border-radius: 7px;
	padding: 10px;
}

.cr-bankBox .avtarIcon {
	border: 2px solid #080955;
	width: 80px;
	height: 80px;
}

.cr-details h2 {
	margin-top: 0;
}

.cr-details p {
	margin: 0;
	line-height: 26px;
}

.cr-docBx {
	border: 1px solid #dedede;
	background: #ffffff;
	border-radius: 7px;
	min-height: 150px;
	max-height: 150px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.cr-docBx a {
	padding: 10px;
}

.cr-details p strong {
	margin-right: 7px;
}

.cr-imgBx {
	width: 100px;
	height: 100px;
	overflow: hidden;
	text-align: center;
}

.c-Salutation-box {
	border: 1px solid #dee2e6;
	background: #f8f9fa;
	margin-top: 10px;
	padding: 20px;
	border-radius: 8px;
	position: relative;
}

.c-Salutation-box h4 {
	margin: 0;
	color: var(--primary-color);
	font-size: 20px;
	margin-bottom: 15px;
	position: absolute;
	top: -15px;
	left: 25px;
	background: #fff;
	padding: 0 7px;
	border-radius: 5px;
}

.cr-removedBtn {
	position: absolute;
	top: -9%;
	right: 20px;
	padding: 6px 7px !important;
	width: auto !important;
	height: auto !important;
	transform: translateY(9%);
}

.cr-pdfTbl table {
	width: 100% !important;
}

.cr-pdfTbl table td:first-child,
.cr-pdfTbl table td:last-child {
	width: 0%;
}

/* 
.cr-pdfTbl table td span {
	font-size: 10px !important;
} */

.c-scale-table {
	/* overflow: auto; */
	overflow-x: auto;
	position: relative;
}

.c-scale-table>div {
	/* transform: scale(1.4); */
	cursor: auto;
	transform-origin: left top;
}

.c-application-btn .p-button {
	height: 26px !important;
	font-size: 15px !important;
}

.round-container {

	display: inline-block;
	position: relative;
	width: 50px;
	height: 50px;
	-moz-border-radius: 100px;
	-webkit-border-radius: 100px;
	border-radius: 100px;
	background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2JiYmJiYiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2VlZWVlZSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
	background: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #bbbbbb), color-stop(100%, #eeeeee));
	background: -moz-linear-gradient(top, #bbbbbb, #eeeeee);
	background: -webkit-linear-gradient(top, #bbbbbb, #eeeeee);
	background: linear-gradient(to bottom, #bbbbbb, #eeeeee);
	-moz-box-shadow: inset 0 -1px 10px #999, 0 -1px 1px #aaa, 0 2px 1px #fff;
	-webkit-box-shadow: inset 0 -1px 10px #999, 0 -1px 1px #aaa, 0 2px 1px #fff;
	box-shadow: inset 0 -1px 10px #999, 0 -1px 1px #aaa, 0 2px 1px #fff;
}

.round-content {
	display: block;
	cursor: pointer;
	backface-visibility: hidden;
	transition: transform 200ms ease-in;
	background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2RkZGRkZCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==);
	background: linear-gradient(to bottom, #ffffff, #dddddd);
	background-color: #fff;
	box-shadow: 0 2px 7px #555;
	text-shadow: 0 -1px 1px #111;
	position: absolute;
	text-align: center;
	color: #990000;
	font-size: 33px;
	line-height: 100%;
	margin: 5px;
	width: 40px;
	height: 40px;
	border-radius: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 2px;
}

.round-content:hover {
	background-color: #eee;
	color: green;
	-moz-transform: rotate(-10deg) scale(0.9);
	-ms-transform: rotate(-10deg) scale(0.9);
	-webkit-transform: rotate(-10deg) scale(0.9);
	transform: rotate(-10deg) scale(0.9);
}

.c-pi-plus .round-content {
	color: #16A34A;

}

.c-pi-minus .round-content {
	color: #4F46E5;

}



.c-resetForm-w {
	max-height: 310px;
	overflow-y: auto;
	min-height: 100%;
}

.c-resetForm-w-2 {
	background-color: #BFE1FF;
}



.c-resetForm-w-2 .field-checkbox {
	display: block;
	margin: 0;
}

.c-resetForm-w-2 .field-checkbox label {
	display: block;
	padding: 9px 0px;
	padding-left: 15px;
	color: var(--primary-color);
	font-weight: bold;
	position: relative;
	border-bottom: 1px solid #aad7fd;
	position: relative;
}

.c-resetForm-w-2 .field-checkbox label:after {
	display: block;
	position: absolute;
	left: 0;
	top: 15px;
	background-color: var(--primary-color);
	width: 6px;
	height: 6px;
	border-radius: 50%;
	content: "";
}